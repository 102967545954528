import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import { PlayerMeta, playerTotalHeight, playerTotalWidth } from '../models/Player.model';
import Player from './Player';

import { tileCountY } from '../models/Board.model';
import { BoardProvider } from '../context/BoardContext';
import { Grid } from './Grid';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '65%',
        height: '90%',
        marginTop: '10px',
        minWidth: '795.31px',
        minHeight: '704px',
    },
    board: {
        position: 'relative',
        width: '65%',
        height: '90%',
    },
    playerContainer: {
        position: 'absolute',
        width: '100%',
        zIndex: 2,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
}));

type BoardProps = {
    players: { [turn: number]: PlayerMeta };
    isMyTurn: boolean;
    socket: any
};

/**
 * RESPONSIBLE FOR RENDERING THE PLAYERS
 */
const Board: FC<BoardProps> = ({ players, isMyTurn, socket }) => {
    const classes = useStyles();
    const tileCountX = 8;
    const containerWidth = playerTotalWidth * tileCountX;
    const containerHeight = playerTotalHeight * tileCountY;

    const renderPlayers = () => {
        return Object.values(players).map((player) => (
            <Player
                playerId={player.id}
                key={`player-${player.user.nickname}`}
                positionInCell={player.positionInCell}
                playerTurn={player.playerTurn}
                playerPosition={player.playerPosition}
                user={player.user}
                color={player.color}
                isMyTurn={isMyTurn}
                socket={socket}
            />
        ));
    };

    return (
        <div className={classes.board}>
            <BoardProvider
                containerWidth={containerWidth}
                containerHeight={containerHeight}
                tileCountX={8}
                tileCountY={10}
            >
                <div className={classes.playerContainer}>{renderPlayers()}</div>
                <Grid />
            </BoardProvider>
        </div>
    );
};

export default Board;
