import React, { useEffect, useState, FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { SOUNDS, useAudio } from '../hooks/useAudio';
import { POSITION } from '../hooks/useGame';
import { User } from '../../../../shared/models';
import { END_DICE_ROLL_DELAY } from '../models/Game.constants';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        fontFamily: 'Luckiest Guy',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '26px',
        letterSpacing: '0.46px',
        textTransform: 'uppercase',
        color: '#575757',

        height: '68px',
        position: 'absolute',

        transitionProperty: 'left, top, transform',
        transitionDuration: '300ms', // Duration per cell movement
        transform: 'scale(1)',

        '& img': {
            height: '100%',
        },
    },
}));

type PlayerProps = {
    playerId: number;
    positionInCell: number;
    playerTurn: number;
    playerPosition: number; // Target position
    user: User;
    color: string;
    isMyTurn: boolean;
    socket: any;
};

const Player: FC<PlayerProps> = ({
    playerId,
    positionInCell,
    playerTurn,
    playerPosition,
    user,
    color,
    socket
}) => {
    const classes = useStyles();
    const tileCountX = 8;
    const moveAudio = useAudio(SOUNDS.MOVE, {});
    const [currentPosition, setCurrentPosition] = useState<number>(playerPosition); // Current cell position

    useEffect(() => {
        const handlePlayerMoved = (data: any) => {
            if (playerId === data.playerId) {
                setTimeout(() => {
                    movePlayer(data.playerNewPosition, data.positionBeforeMoving, moveAudio);
                }, END_DICE_ROLL_DELAY * 1000)
            }
            if (data.playerZampadoId && (playerId === data.playerZampadoId)) {
                let differenceOfPositionsOfTheDicePlayer = Math.abs(data.positionBeforeMoving - data.playerNewPosition)
                let totalTimeToWait = (END_DICE_ROLL_DELAY * 1000) + (differenceOfPositionsOfTheDicePlayer * 300)
                setTimeout(() => {
                    movePlayer(data.playerZampadoNewPosition, data.playerZampadoPositionBeforeMoving, moveAudio);
                }, totalTimeToWait)
            }
        };

        const movePlayerOnReset = () => {
            setCurrentPosition(-1)
        };

        socket.on('playerMoved', handlePlayerMoved);
        socket.on('roomReset', movePlayerOnReset);
        return () => {
            socket.off('playerMoved', handlePlayerMoved); // Clean up listener
            socket.off('roomReset', movePlayerOnReset);

        };

    }, [playerId, moveAudio, socket]);

    const movePlayer = (targetPosition: number, lastPosition: number, moveAudio: HTMLAudioElement) => {
        let timer: NodeJS.Timeout | null = null;
        const moveStepByStep = () => {
            timer = setInterval(() => {
                setCurrentPosition((prev) => {
                    if (prev < targetPosition) {
                        moveAudio.play();
                        return prev + 1;
                    }
                    if (prev > targetPosition) {
                        moveAudio.play();
                        return prev - 1;
                    }
                    clearInterval(timer!);
                    return prev;
                });
            }, 300);
        };

        if (lastPosition !== targetPosition) {
            if (timer) clearInterval(timer);
            moveStepByStep();
        }
    };

    const indexToPosition = (toCell: number): [number, number] => {
        if (toCell !== -1) {
            const cell = POSITION[toCell];
            const x = Math.floor((toCell + 1 !== 81 ? cell : -1) / tileCountX);
            const y = Math.floor(((toCell + 1) - (toCell + 1 !== 81 ? 1 : 2)) / 10);
            return [x, y];
        }
        return [-1, 0];
    };

    const positionToPixelsX = (position: number) => {
        let offset = positionInCell !== 0 && positionInCell !== 1 && positionInCell !== 2 ? 8.6 / 2 : 0;
        if (position === -1) {
            return `calc(100% - 50px + ${offset}% + calc(${position} * 8.4%) + 1%)`;
        } else {
            return `calc(100% - 50px + ${offset}% - calc(${tileCountX - position + 1} * 8.4%) - 16%)`;
        }
    };

    const positionToPixelsY = (position: number) => {
        let offsetInCell = positionInCell !== 0 && positionInCell !== 3 ? (positionInCell % 3) * 4 : 0;
        return `calc(10px + 0.4% + ${offsetInCell}% + calc(${position} * 12.4%))`;
    };

    const positionToZIndex = (position: number) => {
        if (position === -1) return 12;
        return position + 1;
    };

    const [imgUrl, setImgUrl] = useState<string>('');

    useEffect(() => {
        const url = `/static/images/player_${color}.png`;
        setImgUrl(url);
    }, [color]);

    const style = {
        top: positionToPixelsX(indexToPosition(currentPosition)[0]),
        left: positionToPixelsY(indexToPosition(currentPosition)[1]),
        zIndex: positionToZIndex(indexToPosition(currentPosition)[0]),
    };

    return (
        <div className={classes.root} style={style}>
            {user && <img src={imgUrl} alt="" />}
        </div>
    );
};

export default Player;
