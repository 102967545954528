import { Box, Button, Card, makeStyles, TextField, Theme, Typography } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { SOUNDS, useAudio } from './Game/hooks/useAudio';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: '51%',
    height: '67%',
    backgroundImage: `url('/static/images/background_orange.png')`,
    backgroundRepeat: 'round',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    '& img': {
      width: '165px',
      marginTop: '3.6rem'
    }
  },
  buttons: {
    minWidth: '319px',
    marginTop: '2.5rem',
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.45px',
    textTransform: 'uppercase',
    width: '100%',
    boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '24px',
    marginRight: '1rem',
    color: 'white',
    marginTop: '1rem',
  },
  green: {
    backgroundColor: '#83CD24',
    '&:hover': {
      backgroundColor: '#75B820',
    }
  },
  text: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#FFFFFF',
    width: '424px'
  },
  back: {
    color: 'white',
    position: 'absolute',
    top: 16,
    left: 16,
    cursor: 'pointer',
  },
  textfield: {
    '& label': {
      fontFamily: 'Luckiest Guy',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      letterSpacing: '0.15px',
      color: 'white',
    },
    '& label.MuiInputLabel-outlined': {
      width: 'calc(100% - 28px)'
    },
    '& label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      width: 'unset',
    },
    '& label.MuiFormLabel-root.Mui-focused': {
      color: 'white',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'unset'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.66)'
    },
    '& .MuiOutlinedInput-notchedOutline, & .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
    width: '433px',
    height: '40px',
    marginTop: '24px',
  },
  input: {
    '&.MuiInputBase-root': {
      color: 'white',
    },
    '&.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused': {
      borderColor: 'white',
    },
    boxSizing: 'border-box',
    borderRadius: '24px',
  },
}))

interface CodeModalProps {
  onAccept: (code: string, setReplaying: any) => void;
  onCancel: () => void;
  errorCodeMsg: string;
}

const CodeModal: FC<CodeModalProps> = ({ onAccept, onCancel, errorCodeMsg }) => {
  const classes = useStyles();
  const [code, setCode] = useState<string>('');
  const [errorMsg, setErroMsg] = useState<string>('');
  const [replaying, setReplaying] = useState<boolean>(false);

  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {})

  useEffect(() => {
    setErroMsg(errorCodeMsg);
  }, [errorCodeMsg])

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <ArrowBackIcon className={classes.back} onClick={() => { onCancel(); buttonClickAudio.play() }} />
        <Typography className={classes.text} variant="h5">INGRESE EL CÓDIGO QUE DESEA UTILITZAR PARA LA NUEVA PARTIDA</Typography>
        <TextField
          className={classes.textfield}
          inputProps={{ style: { textAlign: 'center' } }}
          InputProps={{ className: classes.input }}
          label={errorCodeMsg ? errorCodeMsg : "Ingrese un CÓDIGO ADMIN"}
          variant="outlined"
          id="code"
          size="small"
          onChange={e => setCode(e.target.value)}
          error={(code === '' && errorMsg !== '') || (code !== '' && errorCodeMsg !== '')}
        />
        <Box className={classes.buttons} >
          <Button
            className={classes.button + ' ' + classes.green}
            onClick={() => { onAccept(code, setReplaying); buttonClickAudio.play(); setReplaying(true) }}
            variant="contained"
            disabled={replaying || code === ""}
          >
            {replaying ? "Creando partida..." : "JUGAR"}
          </Button>
        </Box>
        <img src="/static/images/logo.png" alt="" />
      </Card>
    </Box>
  )
}

export default CodeModal
