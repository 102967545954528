/**
 * CONSTANTS OF THE GAME
 */
export const END_TURN_DELAY = 1.8; // s
export const END_DICE_ROLL_DELAY = 2.2; // s
export const CATEGORY_SHOW_DELAY = 1; // s
export const CATEGORY_SHOW_TIME = 2; // s
export const SHOW_RIGHT_ANSWER_TIME = 3; // s
export const DISPLAY_CORRECT_ANSWER_TIME = 1; // s
export const REROLL_DELAY = 0.5; // s

export const BACKWARDS_DIFF_0 = 0;
export const BACKWARDS_DIFF_1 = 1;
export const BACKWARDS_DIFF_2 = 2;
export const BACKWARDS_DIFF_3 = 3;

export const BACKWARDS_ANIMATION = 1; //s

export const DISPLAY_CHOSEN_CATEGORIES = 2; // s

export const GUESS_ALL_MULTICOLOR = 12;
export const MULTICOLOR_MOVEMENT_TIME = 5; // s

export const END_GAME_DELAY = 1; 