import { Box, makeStyles, Theme } from '@material-ui/core'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useCallback } from 'react'
import { couldStartTrivia } from 'typescript'
import { Game, Room, User } from '../../../shared/models'
import Board from './components/Board'
import CategoryModal from './components/CategoryModal'
import DiceModal from './components/DiceModal'
import MulticolorModal from './components/MulticolorModal'
import PlayerTag from './components/PlayerTag'
import QuestionModal from './components/QuestionModal'
import { SOUNDS, useAudio } from './hooks/useAudio'
import { useGame } from './hooks/useGame'
import { usePrev } from './hooks/usePrev'
import { CATEGORY_SHOW_DELAY, DISPLAY_CHOSEN_CATEGORIES, DISPLAY_CORRECT_ANSWER_TIME, END_DICE_ROLL_DELAY, END_TURN_DELAY, MULTICOLOR_MOVEMENT_TIME } from './models/Game.constants'
import { PlayerMeta } from './models/Player.model'
import CommentModal from './components/CommentModal'
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: -1
  },
  leftPlayers: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: '4rem',
    marginBottom: '3.5rem',
    marginRight: '2.5rem',
  },
  rightPlayers: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: '4rem',
    marginBottom: '3.5rem',
    marginLeft: '2.5rem',
  }
}))

interface GameProps {
  room: Room;
  game: Game;
  passGameState: (gameState: string) => void;
  onPassWinner: (winner: User | null) => void;
  forceNextTurn: any;
  socket: any;
}


// GAME STATES
//   IDLE,
//   START_TURN, // Modal dice
//   ROLLED, // Player rolled dice
//   MOVE_PLAYER, // animation
//   CATEGORY, // Show category
//   QUESTION, // Question modal
//   MULTICOLOR, // Choose categories
//   MULTICOLOR_SUCCESS, // if user succeed multicolor state
//   BLACKHOLE,
//   END_TURN, // prepare next turn
//   END_GAME

/**
 * COMBINE BOARD, GRID AND PLAYERS
 * All player's decisions are sent to sockets, and then the app reacts to the correspondent action 
 * 
 */
const GameComponent: FC<GameProps> = ({ room, game, passGameState, onPassWinner, forceNextTurn, socket }) => {
  const classes = useStyles();

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  UI variables
  //
  //////////////////////////////////////////////////////////////////////////////////////////////////////

  const [leftPlayers, setLeftPlayers] = useState<PlayerMeta[]>([])
  const [rightPlayers, setRightPlayers] = useState<PlayerMeta[]>([])

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Game variables
  //
  //////////////////////////////////////////////////////////////////////////////////////////////////////

  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
  const [finalRoll, setfinalRoll] = useState<number[]>([]);
  const [finalAnswer, setfinalAnswer] = useState<number>(-1);
  const [finalCategory, setfinalCategory] = useState<any>(-1);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState<boolean>(false);

  const diesAudio = useAudio(SOUNDS.DIES, {})
  const correctAudio = useAudio(SOUNDS.CORRECT, {})
  const wrongAudio = useAudio(SOUNDS.WRONG, {})

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Game Reducer variables
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const [state, initReducer, checkPlayersAndCallback, setGameStateEvent,
    startTurn,
    showDiceModal, setShowDiceModal,
    movePlayerNCells,
    prepareQuestionFlow, prepareBlackholeQuestions,
    displayCategory, displayQuestion, showQuestionModal,
    handleAnswer,
    showMulticolorModal, displayMulticolor, hideMulticolor, chooseMulticolor,
    onUnmount,
    handleCommentModal, showCommentModal,
    hideQuestionModalAndShowCommentModal
  ] = useGame(socket);
  let updatedState = useRef(state);
  const prevGameState = usePrev(updatedState.current.gameState);

  /////////////////////////////////////////////////////////////////////////////
  //
  //  Change GameState Methods
  //
  ////////////////////////////////////////////////////////////////////////////

  const handleRolled = () => {
    setTimeout(() => {
      setShowDiceModal(false);
      setfinalRoll([])
      setGameStateEvent("MOVE_PLAYER")
    }, END_DICE_ROLL_DELAY * 1000)
  }

  const localMovePlayer = () => {
    if (updatedState.current.players[updatedState.current.currentTurnPlayer]?.playerPosition !== 80) {
      if (updatedState.current.diceRoll) {
        let waitingTime = updatedState.current.diceRoll * 300;
        if (updatedState.current.isZampado) {
          waitingTime = waitingTime * 2
        }
        setTimeout(() => {
          prepareQuestionFlow();
        }, waitingTime)
      }
      else {
        setTimeout(() => {
          prepareQuestionFlow();
        }, CATEGORY_SHOW_DELAY * 1000)
      }
    }
    else {
      setGameStateEvent("END_GAME");
    }
  }

  const multicolorSuccess = () => {
    setTimeout(() => {
      setGameStateEvent("START_TURN");
    }, MULTICOLOR_MOVEMENT_TIME * 1000)
  }


  const handleEndTurn = () => {
    setTimeout(() => {
      setGameStateEvent("START_TURN");
    }, END_TURN_DELAY * 1000)
  }

  /////////////////////////////////////////////////////////////////////////////
  //
  //  Socket Events Receptors | GameState Change Methods
  //
  ////////////////////////////////////////////////////////////////////////////

  const diceRolled = (dices_values: number[]) => {
    diesAudio.play()
    setfinalRoll(dices_values);
    updatedState.current.diceRoll = dices_values[0] + dices_values[1]

    const currentPlayerId = updatedState.current.currentTurnPlayer;
    const currentPlayer = updatedState.current.players[currentPlayerId];
    const targetPosition = currentPlayer.playerPosition + dices_values[0] + dices_values[1];

    updatedState.current.isZampado = false;

    for (const turn in updatedState.current.players) {
      const otherPlayer = updatedState.current.players[parseInt(turn)];

      if (
        otherPlayer.id !== currentPlayer.id &&
        otherPlayer.playerPosition === targetPosition
      ) {
        updatedState.current.isZampado = true;
        break;
      }
    }
    movePlayerNCells(dices_values[0] + dices_values[1], false);
    socket.emit('rolled', { roomCode: updatedState.current.roomCode });
    setTimeout(() => {
      setShowDiceModal(false);
      setfinalRoll([])
      setGameStateEvent("MOVE_PLAYER", dices_values[0] + dices_values[1])
    }, END_DICE_ROLL_DELAY * 1000)
  }

  const answerChosen = (answer: number, guessed: boolean) => {
    if (guessed) {
      correctAudio.play();
      setIsAnswerCorrect(true);
    } else {
      wrongAudio.play()
      setIsAnswerCorrect(false);
    }
    setfinalAnswer(answer);
    hideQuestionModalAndShowCommentModal();
  }

  const continueButtonAction = (guessed: boolean) => {
    setTimeout(() => {
      handleCommentModal(guessed);
      setfinalAnswer(-1);
    }, DISPLAY_CORRECT_ANSWER_TIME * 1000)
  }




  const categoriesChosen = (category1: any, category2: any) => {
    setTimeout(() => {
      hideMulticolor();
      chooseMulticolor(category1, category2);
    }, DISPLAY_CHOSEN_CATEGORIES * 1000)
  };

  const multicolorEvent = () => {
    if (updatedState.current.multicolorQueue.length === 0) {
      checkPlayersAndCallback(() => {
        socket.emit('broadcast', { event: 'multicolorEvent', value: { show: true, startTimestamp: updatedState.current.startTimestampState, endTimestamp: updatedState.current.endTimestampState }, roomCode: updatedState.current.roomCode });
      })
    } else {
      setGameStateEvent("CATEGORY")
    }
  };

  const questionEvent = () => {
    if (!updatedState.current.currentRemainingQuestion?.answered) {
      checkPlayersAndCallback(() => {
        socket.emit('broadcast', { event: 'showQuestion', value: { show: true, startTimestamp: updatedState.current.startTimestampState, endTimestamp: updatedState.current.endTimestampState }, roomCode: updatedState.current.roomCode });
      })
    } else {
      setTimeout(() => {
        handleAnswer(updatedState.current.currentRemainingQuestion?.answeredCorrectly || false);
        setfinalAnswer(-1);
      }, DISPLAY_CORRECT_ANSWER_TIME * 1000)
    }
  }

  const rolled = (value: number) => {
    let dice1 = 1;
    let dice2 = 2;

    for (var i = 0; i < Infinity; i++) {
      if (value < 7) {
        dice1 = Math.floor((Math.random() * (value - 1)) + 1)
      }
      else {
        dice1 = Math.floor((Math.random() * 6) + 1)
      }
      dice2 = value - dice1;
      if (dice2 <= 6 && dice1 > 0 && dice2 > 0) {
        break;
      }
    }
    checkPlayersAndCallback(() => {
      socket.emit('broadcast', { event: 'diceRolled', value: [dice1, dice2], roomCode: updatedState.current.roomCode });
    })
  }

  const hasTimeout = useCallback(() => {
    setShowDiceModal(false)
    hideMulticolor();

    checkPlayersAndCallback(() => {
      socket.emit('timedout', { roomCode: updatedState.current.roomCode });
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const commentModalContinueClick = (answer: number, guessed: boolean) => {
    checkPlayersAndCallback(() => {
      socket.emit('broadcast', { event: 'continueClicked', value: { answer, guessed }, roomCode: updatedState.current.roomCode });
    })
  }

  const chooseAnswer = (answer: number, guessed: boolean) => {
    checkPlayersAndCallback(() => {
      socket.emit('answeredQuestion', { roomCode: updatedState.current.roomCode, guessed });
      socket.emit('broadcast', { event: 'chooseAnswer', value: { answer, guessed }, roomCode: updatedState.current.roomCode });
    })
  }

  const chooseCategories = useCallback((category1: any, category2: any) => {
    checkPlayersAndCallback(() => {
      socket.emit('broadcast', { event: 'chooseCategories', value: { category1, category2 }, roomCode: updatedState.current.roomCode })
      setfinalCategory(-1);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedCategory = (category: any) => {
    checkPlayersAndCallback(() => {
      socket.emit('broadcast', { event: 'selectedCategory', value: { category }, roomCode: updatedState.current.roomCode });
    })
  }

  const handleEndGame = () => {
    socket.emit('requestWinner', { roomCode: updatedState.current.roomCode }, (res: any) => {
      onPassWinner(res.winner);
    })
  }

  /////////////////////////////////////////////////////////////////////////////
  //
  //  SOCKET LISTENERS
  //
  ////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    let isCancelled = false;
    const runAsync = async () => {
      try {
        socket.on('gameUpdated', (res: { room: Room, game: Game }) => {
          if (res) {
            let half = Math.round(res.room.roomPlayers.length / 2);
            let l: any[] = [], r: any[] = [];
            for (let i = 0; i < half; ++i) {
              l.push(renderNickname(res.room.roomPlayers[i].user, res.game.currentTurnPlayer === res.room.roomPlayers[i].playerTurn, res.room.roomPlayers[i].playerTurn, res.room.roomPlayers[i].color));
            }
            for (let i = half; i < res.room.roomPlayers.length; ++i) {
              r.push(renderNickname(res.room.roomPlayers[i].user, res.game.currentTurnPlayer === res.room.roomPlayers[i].playerTurn, res.room.roomPlayers[i].playerTurn, res.room.roomPlayers[i].color))
            }

            setLeftPlayers(l.reverse());
            setRightPlayers(r.reverse());
            let stateFromRoomAndGame = {
              ...updatedState.current,
              topic: res.room.topic,
              gameState: res.game.gameState,
              players: {},
              currentTurnPlayer: res.game.currentTurnPlayer,
              isMyTurn: false,
              multicolorQueue: res.game.multiColorQueue,
              blackholeQueue: res.game.blackholeQueue,
              currentRemainingQuestion: res.game.currentRemainingQuestion,
              startTimestampState: res.game.startTimestampState ? new Date(res.game.startTimestampState) : null,
              endTimestampState: res.game.endTimestampState ? new Date(res.game.endTimestampState) : null,
            }
            initReducer(stateFromRoomAndGame, res.room.roomPlayers);

            passGameState(res.game.gameState);
          }
        })

        socket.on('startTurn', (value: any) => { setShowDiceModal(value) });

        socket.on('showQuestion', (value: any) => { displayQuestion(value) });

        socket.on('diceRolled', (value: number[]) => { diceRolled(value) });

        socket.on('multicolorEvent', (value: any) => { displayMulticolor(value) })

        socket.on('chooseAnswer', (value: { answer: number, guessed: boolean }) => { answerChosen(value.answer, value.guessed) })

        socket.on('continueClicked', (value: { guessed: boolean }) => { continueButtonAction(value.guessed) })

        socket.on('chooseCategories', (value: { category1: any, category2: any }) => { categoriesChosen(value.category1, value.category2) })

        socket.on('selectedCategory', (value: { category: any }) => { setfinalCategory(value.category) })

      } catch (e) {
        if (!isCancelled) throw e;
      }
    }

    if (socket) runAsync();

    return () => {
      socket.off('gameUpdated', () => { })
      socket.off('startGame', () => { })
      socket.off('startTurn', () => { })
      socket.off('showQuestion', () => { })
      socket.off('diceRolled', () => { })
      socket.off('multicolorEvent', () => { })
      socket.off('chooseAnswer', () => { })
      socket.off('continueClicked', () => { })
      socket.off('chooseCategories', () => { })
      socket.off('selectedCategory', () => { })
      isCancelled = true;

      onUnmount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //////////////////////////////////////////////////////////////////////////////
  //
  //  UPDATE METHODS 
  //
  ////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (forceNextTurn !== 0) {
      setTimeout(() => {
        setGameStateEvent('END_TURN');
      }, END_TURN_DELAY * 1000)
    }
  }, [forceNextTurn])

  // Update Player piece
  // update playerTag at players or turn update
  useEffect(() => {
    if (room && room.id !== -1 && game && game.id !== -1) {
      // updates playerstags
      let half = Math.round(room.roomPlayers.length / 2);
      let l: any[] = [], r: any[] = [];
      for (let i = 0; i < half; ++i) {
        l.push(renderNickname(room.roomPlayers[i]?.user, game.currentTurnPlayer === room.roomPlayers[i].playerTurn, room.roomPlayers[i].playerTurn, room.roomPlayers[i].color));
      }
      for (let i = half; i < room.roomPlayers.length; ++i) {
        r.push(renderNickname(room.roomPlayers[i]?.user, game.currentTurnPlayer === room.roomPlayers[i].playerTurn, room.roomPlayers[i].playerTurn, room.roomPlayers[i].color))
      }

      setLeftPlayers(l.reverse());
      setRightPlayers(r.reverse());

      let stateFromRoomAndGame = updatedState.current;

      // adding room info
      stateFromRoomAndGame = {
        ...stateFromRoomAndGame,
        roomCode: room.roomCode,
        topic: room.topic,
        players: {},
      }

      // to solve the bug where it randomly puts the state to IDLE
      if (
        (updatedState.current.gameState === "END_GAME" && game.gameState === "IDLE") ||
        (updatedState.current.gameState !== "END_GAME" && game.gameState !== "IDLE") ||
        (updatedState.current.gameState === "NONE")
      )
        // updates local game
        stateFromRoomAndGame = {
          ...stateFromRoomAndGame,
          gameId: game.id,
          gameState: game.gameState,
          currentTurnPlayer: game.currentTurnPlayer,
          isMyTurn: false,
          multicolorQueue: game.multiColorQueue,
          blackholeQueue: game.blackholeQueue,
          currentRemainingQuestion: game.currentRemainingQuestion,
          startTimestampState: game.startTimestampState ? new Date(game.startTimestampState) : null,
          endTimestampState: game.endTimestampState ? new Date(game.endTimestampState) : null,
        }
      initReducer(stateFromRoomAndGame, room.roomPlayers);
    }
  }, [room, game, initReducer])

  // first action of the gameState
  const handleChangeGameState = useCallback((gameState: string) => {
    switch (gameState) {
      case "START_TURN": startTurn(); break;
      case "ROLLED": handleRolled(); break;
      case "MOVE_PLAYER": localMovePlayer(); break;
      case "CATEGORY": setShowCategoryModal(true); displayCategory(); break;
      case "QUESTION": setShowCategoryModal(false); questionEvent(); break;
      case "MULTICOLOR": multicolorEvent(); break;
      case "MULTICOLOR_SUCCESS": multicolorSuccess(); break;
      case "BLACKHOLE": prepareBlackholeQuestions(); break;
      case "END_TURN": handleEndTurn(); break;
      case "END_GAME": handleEndGame(); break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (updatedState.current.gameState !== state.gameState) {
      updatedState.current = state;

      if (updatedState.current.gameId !== -1 && prevGameState !== updatedState.current.gameState) {
        passGameState(updatedState.current.gameState);
        handleChangeGameState(updatedState.current.gameState);
      }
    }
  }, [state, passGameState, prevGameState, handleChangeGameState])

  const renderNickname = (user: User | undefined, myTurn: boolean, pos: number, color: string) => {
    return <PlayerTag user={user} myTurn={myTurn} pos={pos} color={color} key={'tag-' + user?.nickname} />
  }

  ///////////////////   Getters

  const getCurrentPlayerNickname = () => {
    return updatedState.current.players[updatedState.current.currentTurnPlayer].user.nickname || '';
  }

  const getCurrentPlayerColor = () => {
    return updatedState.current.players[updatedState.current.currentTurnPlayer].color || 'red';
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.leftPlayers}>
        {leftPlayers}
      </Box>
      <Board players={state.players} isMyTurn={state.isMyTurn} socket={socket} />
      <Box className={classes.rightPlayers}>
        {rightPlayers}
      </Box>
      {state.gameState === "START_TURN" && showDiceModal &&
        <DiceModal color={getCurrentPlayerColor()} isMyTurn={state.isMyTurn} nickname={getCurrentPlayerNickname()} rolled={rolled} timeout={hasTimeout} finalRoll={finalRoll} startTimestamp={updatedState.current.startTimestampState?.getTime() || -1} endTimestamp={updatedState.current.endTimestampState?.getTime() || -1} />
      }
      {state.gameState === "CATEGORY" && state.currentRemainingQuestion !== null && showCategoryModal &&
        <CategoryModal category={state.currentRemainingQuestion.question.subtopic} topic={state.topic} />
      }
      {state.gameState === "QUESTION" && state.currentRemainingQuestion !== null && showQuestionModal &&
        <QuestionModal color={state.players[state.currentTurnPlayer]?.color} isMyTurn={state.isMyTurn} nickname={state.players[state.currentTurnPlayer]?.user.nickname} timeout={hasTimeout} question={state.currentRemainingQuestion.question} topic={state.topic} chooseAnswer={chooseAnswer} finalAnswer={finalAnswer} startTimestamp={updatedState.current.startTimestampState?.getTime() || -1} endTimestamp={updatedState.current.endTimestampState?.getTime() || -1} />
      }
      {showCommentModal && state.currentRemainingQuestion !== null &&
        <CommentModal
          isCorrect={isAnswerCorrect}
          question={state.currentRemainingQuestion.question}
          isMyTurn={state.isMyTurn}
          commentModalContinueClick={commentModalContinueClick}
        />
      }
      {state.gameState === "MULTICOLOR" && showMulticolorModal &&
        <MulticolorModal
          color={getCurrentPlayerColor()}
          isMyTurn={state.isMyTurn}
          nickname={state.players[state.currentTurnPlayer]?.user.nickname}
          timeout={hasTimeout}
          chooseCategories={chooseCategories}
          topic={state.topic}
          finalCategory={finalCategory}
          selectedCategory={selectedCategory}
          startTimestamp={updatedState.current.startTimestampState?.getTime() || -1}
          endTimestamp={updatedState.current.endTimestampState?.getTime() || -1}
        />
      }
    </Box>
  )
}

export default GameComponent
