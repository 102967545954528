import { Box, Card, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';

import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { SOUNDS, useAudio } from './Game/hooks/useAudio';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    width: '333px',
    maxHeight: '503px',
    right: '0px',
    top: '95px',
    padding: '18px',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
  },
  row: {
    display: 'flex',
  },
  close: {
    cursor: 'pointer',
  },
  rules: {
    maxHeight: '410px',
    overflow: 'auto',
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textAlign: 'left',
    color: '#000000',
  },
  inline: {
    position: 'initial',
    width: '100%',
    maxHeight: '100%',
    padding: 0,
    boxShadow: 'none',
  },
}));

interface RulesProps {
  onClose: () => void;
  inline: boolean;
}

const Rules: FC<RulesProps> = ({ onClose, inline }) => {
  const classes = useStyles();

  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {});

  return (
    <Card className={classes.root + (inline ? ' ' + classes.inline : '')}>
      <Box className={classes.top}>
        <Box className={classes.row}>
          <InfoIcon color="action" style={{ marginRight: '10px' }} />
          <Typography className={classes.title}>REGLAMENTO</Typography>
        </Box>
        <CloseIcon
          className={classes.close}
          color="inherit"
          onClick={() => {
            onClose();
            buttonClickAudio.play();
          }}
        />
      </Box>
      <Box className={classes.rules}>
        <p className={classes.text}>
          <b>Objetivo:</b> Llegar a la casilla de Llegada el primero.<br /><br />

          <b>Tablero:</b> El tablero consiste en un recorrido con 80 casillas de diferentes colores y dificultades.<br /><br />

          <b>Estructura de los turnos:</b> Cada turno el jugador empieza tirando los dados y avanzando, al llegar a la nueva casilla responde una pregunta. Si acierta puede volver a tirar dados, avanzar y responder pregunta. Si falla retrocede.<br /><br />

          <b>Avanzar:</b> Cada vez que se acierte una pregunta, se tiran los dados y se avanza el resultado.<br /><br />

          <b>Retroceder:</b> Cada vez que se falle una pregunta se retrocede según el nivel de dificultad de la pregunta:<br />
          &nbsp;&nbsp;&nbsp;&nbsp;Nivel básico: retrocede 0 casillas<br />
          &nbsp;&nbsp;&nbsp;&nbsp;Nivel inicial: retrocede 1 casilla<br />
          &nbsp;&nbsp;&nbsp;&nbsp;Nivel medio: retrocede 2 casillas<br />
          &nbsp;&nbsp;&nbsp;&nbsp;Nivel experto: retrocede 3 casillas<br /><br />

          <b>¡ZAMPA!:</b> Cuando un jugador llega a la misma casilla que otro jugador, el 2º jugador (el que ya estaba en la casilla), viaja a la casilla del que provenía el 1º jugador.<br /><br />

          <b>Casilla Segura:</b> En esta casilla, no hay preguntas, ni te pueden zampar.<br /><br />

          <b>Casilla Multicolor:</b> El jugador debe escoger 2 colores y responder a 1 pregunta de cada. Si acierta ambas, avanza 12 casillas! Si falla alguna no avanza ni retrocede.<br /><br />

          <b>Casilla Agujero Negro:</b> El jugador debe responder una pregunta de cada color. Si las acierta todas puede tirar los dados de manera normal. Si falla alguna pierde 2 turnos. En esta casilla no te pueden zampar. En el agujero negro hay espacio para todos… Muahahahaha!<br />
        </p>
      </Box>
    </Card>
  );
};

export default Rules;
