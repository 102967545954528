import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useBoard } from "../hooks/useBoard";

export enum S_TYPE {
  YELLOW, BLUE, ORANGE, GREEN, SECURITY_POINT, MULTICOLOR, BLACKHOLE, SIDE, START, LOGO, END
}

// GAME CELLS
export const CELL_INFO = [
  { type: S_TYPE.GREEN, label: '10', direction: ['right', 'bottom'] }, { type: S_TYPE.BLUE, label: '11', direction: ['left', 'bottom'] }, { type: S_TYPE.GREEN, label: '30', direction: ['right', 'bottom'] }, { type: S_TYPE.YELLOW, label: '31', direction: ['left', 'bottom'] }, { type: S_TYPE.BLUE, label: '50', direction: ['right', 'bottom'] }, { type: S_TYPE.GREEN, label: '51', direction: ['left', 'bottom'] }, { type: S_TYPE.GREEN, label: '70', direction: ['right', 'bottom'] }, { type: S_TYPE.BLUE, label: '71', direction: ['left', 'bottom'] },
  { type: S_TYPE.YELLOW, label: '9', direction: ['top', 'bottom'] }, { type: S_TYPE.YELLOW, label: '12', direction: ['top', 'bottom'] }, { type: S_TYPE.BLUE, label: '29', direction: ['top', 'bottom'] }, { type: S_TYPE.GREEN, label: '32', direction: ['top', 'bottom'] }, { type: S_TYPE.YELLOW, label: '49', direction: ['top', 'bottom'] }, { type: S_TYPE.BLUE, label: '52', direction: ['top', 'bottom'] }, { type: S_TYPE.YELLOW, label: '69', direction: ['top', 'bottom'] }, { type: S_TYPE.ORANGE, label: '72', direction: ['top', 'bottom'] },
  { type: S_TYPE.ORANGE, label: '8', direction: ['top', 'bottom'] }, { type: S_TYPE.GREEN, label: '13', direction: ['top', 'bottom'] }, { type: S_TYPE.YELLOW, label: '28', direction: ['top', 'bottom'] }, { type: S_TYPE.BLUE, label: '33', direction: ['top', 'bottom'] }, { type: S_TYPE.GREEN, label: '48', direction: ['top', 'bottom'] }, { type: S_TYPE.YELLOW, label: '53', direction: ['top', 'bottom'] }, { type: S_TYPE.BLUE, label: '68', direction: ['top', 'bottom'] }, { type: S_TYPE.YELLOW, label: '73', direction: ['top', 'bottom'] },
  { type: S_TYPE.BLUE, label: '7', direction: ['top', 'bottom'] }, { type: S_TYPE.ORANGE, label: '14', direction: ['top', 'bottom'] }, { type: S_TYPE.MULTICOLOR, label: '27', direction: ['top', 'bottom'] }, { type: S_TYPE.ORANGE, label: '34', direction: ['top', 'bottom'] }, { type: S_TYPE.BLUE, label: '47', direction: ['top', 'bottom'] }, { type: S_TYPE.ORANGE, label: '54', direction: ['top', 'bottom'] }, { type: S_TYPE.GREEN, label: '67', direction: ['top', 'bottom'] }, { type: S_TYPE.GREEN, label: '74', direction: ['top', 'bottom'] },
  { type: S_TYPE.SECURITY_POINT, label: '6', direction: ['top', 'bottom'] }, { type: S_TYPE.YELLOW, label: '15', direction: ['top', 'bottom'] }, { type: S_TYPE.GREEN, label: '26', direction: ['top', 'bottom'] }, { type: S_TYPE.YELLOW, label: '35', direction: ['top', 'bottom'] }, { type: S_TYPE.SECURITY_POINT, label: '46', direction: ['top', 'bottom'] }, { type: S_TYPE.ORANGE, label: '55', direction: ['top', 'bottom'] }, { type: S_TYPE.YELLOW, label: '66', direction: ['top', 'bottom'] }, { type: S_TYPE.BLACKHOLE, label: '', direction: ['top', 'bottom'] },
  { type: S_TYPE.YELLOW, label: '5', direction: ['top', 'bottom'] }, { type: S_TYPE.ORANGE, label: '16', direction: ['top', 'bottom'] }, { type: S_TYPE.BLUE, label: '25', direction: ['top', 'bottom'] }, { type: S_TYPE.ORANGE, label: '36', direction: ['top', 'bottom'] }, { type: S_TYPE.ORANGE, label: '45', direction: ['top', 'bottom'] }, { type: S_TYPE.YELLOW, label: '56', direction: ['top', 'bottom'] }, { type: S_TYPE.ORANGE, label: '65', direction: ['top', 'bottom'] }, { type: S_TYPE.GREEN, label: '76', direction: ['top', 'bottom'] },
  { type: S_TYPE.GREEN, label: '4', direction: ['top', 'bottom'] }, { type: S_TYPE.YELLOW, label: '17', direction: ['top', 'bottom'] }, { type: S_TYPE.SECURITY_POINT, label: '24', direction: ['top', 'bottom'] }, { type: S_TYPE.GREEN, label: '37', direction: ['top', 'bottom'] }, { type: S_TYPE.YELLOW, label: '44', direction: ['top', 'bottom'] }, { type: S_TYPE.MULTICOLOR, label: '57', direction: ['top', 'bottom'] }, { type: S_TYPE.SECURITY_POINT, label: '64', direction: ['top', 'bottom'] }, { type: S_TYPE.ORANGE, label: '77', direction: ['top', 'bottom'] },
  { type: S_TYPE.ORANGE, label: '3', direction: ['top', 'bottom'] }, { type: S_TYPE.GREEN, label: '18', direction: ['top', 'bottom'] }, { type: S_TYPE.YELLOW, label: '23', direction: ['top', 'bottom'] }, { type: S_TYPE.ORANGE, label: '38', direction: ['top', 'bottom'] }, { type: S_TYPE.BLUE, label: '43', direction: ['top', 'bottom'] }, { type: S_TYPE.ORANGE, label: '58', direction: ['top', 'bottom'] }, { type: S_TYPE.GREEN, label: '63', direction: ['top', 'bottom'] }, { type: S_TYPE.ORANGE, label: '78', direction: ['top', 'bottom'] },
  { type: S_TYPE.BLUE, label: '2', direction: ['top', 'bottom'] }, { type: S_TYPE.GREEN, label: '19', direction: ['top', 'bottom'] }, { type: S_TYPE.BLUE, label: '22', direction: ['top', 'bottom'] }, { type: S_TYPE.BLUE, label: '39', direction: ['top', 'bottom'] }, { type: S_TYPE.GREEN, label: '42', direction: ['top', 'bottom'] }, { type: S_TYPE.ORANGE, label: '59', direction: ['top', 'bottom'] }, { type: S_TYPE.BLUE, label: '62', direction: ['top', 'bottom'] }, { type: S_TYPE.BLUE, label: '79', direction: ['top', 'bottom'] },
  { type: S_TYPE.YELLOW, label: '1', direction: ['top', 'bottom'] }, { type: S_TYPE.BLUE, label: '20', direction: ['top', 'right'] }, { type: S_TYPE.GREEN, label: '21', direction: ['left', 'top'] }, { type: S_TYPE.YELLOW, label: '40', direction: ['top', 'right'] }, { type: S_TYPE.ORANGE, label: '41', direction: ['top', 'left'] }, { type: S_TYPE.YELLOW, label: '60', direction: ['top', 'right'] }, { type: S_TYPE.ORANGE, label: '61', direction: ['top', 'left'] }, { type: S_TYPE.YELLOW, label: '80', direction: ['top', 'bottom'] },
];

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%',
    gridTemplateRows: '7% 8.4% 8.4% 8.4% 8.4% 8.4% 8.4% 8.4% 8.4% 8.4% 8.4% 9%',
    background: 'white',
    backgroundColor: '#FFFFFF',
    border: '2px solid #FFFFFF',
    boxSizing: 'border-box',
    padding: '5px',

    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
    color: '#575757',
  },
  yellowCell: {
    backgroundColor: '#FFE348'
  },
  blueCell: {
    backgroundColor: '#78AEEA'
  },
  orangeCell: {
    backgroundColor: '#FFA258'
  },
  greenCell: {
    backgroundColor: '#83CD24'
  },
  greyCell: {
    backgroundColor: '#E2E2E2'
  },
  blackCell: {
    backgroundColor: '#000000'
  },
  white: {
    color: 'white'
  },
  square: {
    '& > img': {
      height: 'calc(100% - 22px)',
    },
    float: 'left',
    width: '100%',
    height: '100%',
    minWidth: '95px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: '#FFFFFF',
    boxSizing: 'border-box',
    boxShadow: 'inset -4px -4px 10px rgba(0, 0, 0, 0.15), inset 5px 5px 10px rgba(0, 0, 0, 0.15)',
  },
  corner: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
    color: '#575757',
    float: 'left',
    paddingLeft: '5px'
  },
  center: {
    '& .MuiTypography-root': {
      maxWidth: '103px',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'relative',
    top: '-27px',
  },
  securityPoint: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '11px',
    lineHeight: '12px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
    color: '#575757',
  },
}))

/**
 * RENDERS THE BOARD
 */
export const Grid = () => {
  const [, , tileCountX, tileCountY] = useBoard();
  const classes = useStyles();

  const renderTop = () => {
    const cells = [] as JSX.Element[];
    const labels = ['BÁSICO', 'INTERMEDIO', 'AVANZADO', 'EXPERTO'];
    for (let i = 0; i < 4; ++i) {
      cells.push(
        <div key={`top-${i}`} className={`${classes.greyCell} ${classes.square}`} style={{ gridColumn: `${i * 2 + 1}/${i * 2 + 3}` }}>
          {
            labels[i]
          }
        </div>);
    }

    return cells;
  }

  const renderBot = () => {
    const cells = [] as JSX.Element[];
    cells.push(
      <div key={`start`} className={`${classes.blackCell} ${classes.square} ${classes.white}`}>
        SALIDA
      </div>);
    cells.push(
      <div key={`logo`} className={`${classes.greyCell} ${classes.square}`} style={{ gridColumn: `2/8` }}>
        <img src="/static/images/logo.png" alt="Logo" />
      </div>);
    cells.push(
      <div key={`end`} className={`${classes.greenCell} ${classes.square} ${classes.white}`}>
        LLEGADA
      </div>);
    return cells;
  }

  const renderGrid = () => {
    const length = tileCountX * tileCountY;
    const cells = [] as JSX.Element[];

    for (let index = 0; index < length; index++) {
      const cellInfo = CELL_INFO[index];
      const type = cellInfo ? cellInfo.type : S_TYPE.YELLOW;
      const backgroundColor = setBackgroundColor(type);
      const directions = cellInfo && cellInfo.direction ? cellInfo.direction : [];

      const borderStyles = {
        borderTop: directions.includes('top') ? 'none' : '5px solid white',
        borderRight: directions.includes('right') ? 'none' : '5px solid white',
        borderBottom: directions.includes('bottom') ? 'none' : '5px solid white',
        borderLeft: directions.includes('left') ? 'none' : '5px solid white',
      };

      const white = type === S_TYPE.START || type === S_TYPE.END;
      const normal =
        type !== S_TYPE.SECURITY_POINT &&
        type !== S_TYPE.LOGO &&
        type !== S_TYPE.BLACKHOLE &&
        type !== S_TYPE.MULTICOLOR;

      cells.push(
        <div
          key={`${index}`}
          className={`${backgroundColor} ${classes.square} ${white ? classes.white : ''}`}
          style={borderStyles}
        >
          {normal && cellInfo ? cellInfo.label : ''}
          {type === S_TYPE.SECURITY_POINT && (
            <Box height="100%">
              <Typography className={classes.corner}>{cellInfo ? cellInfo.label : ''}</Typography>
              <Box className={classes.center}>
                <Typography className={classes.securityPoint}>PUNTO DE SEGURIDAD</Typography>
              </Box>
            </Box>
          )}
          {type === S_TYPE.BLACKHOLE && (
            <img width="100%" src="/static/images/blackhole.png" alt="Logo" />
          )}
          {type === S_TYPE.MULTICOLOR && (
            <img width="100%" src="/static/images/multicolor.png" alt="Logo" />
          )}
        </div>
      );
    }

    return cells;
  };



  const setBackgroundColor = (type: S_TYPE) => {
    switch (type) {
      case S_TYPE.YELLOW: return classes.yellowCell;
      case S_TYPE.BLUE: return classes.blueCell;;
      case S_TYPE.ORANGE: return classes.orangeCell;
      case S_TYPE.GREEN:
      case S_TYPE.END: return classes.greenCell;
      case S_TYPE.SECURITY_POINT:
      case S_TYPE.SIDE:
      case S_TYPE.LOGO: return classes.greyCell;
      case S_TYPE.BLACKHOLE:
      case S_TYPE.START: return classes.blackCell;
    }
  }

  return (
    <div className={classes.grid}>
      {renderTop()}
      {renderGrid()}
      {renderBot()}
    </div>);
};